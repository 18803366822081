import { postRequest, getRequest, deleteRequest } from './index'
import { SRCURL, baseURL } from '@/systemConfiguration/index'

// 商城-品类列表查询
export const goodsGetGoodsCategoryList = (successCallback, failureCallback) => {
  getRequest(`/goods/getGoodsCategoryList`, {}, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}
// 商城-规格列表查询
export const goodsGetGoodsSpecList = (categoryCode, successCallback, failureCallback) => {
  getRequest(`/goods/getGoodsSpecList/${categoryCode}`, {}, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}
// 商城-材质列表查询
export const goodsGetGoodsMaterialList = (categoryCode, successCallback, failureCallback) => {
  getRequest(`/goods/getGoodsMaterialList/${categoryCode}`, {}, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}
// 订单-平台-查询订单
export const orderPagePlatOrder = (data, successCallback, failureCallback) => {
  postRequest(`${SRCURL}/trade/order/pagePlatOrder`, data, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}
// 订单-查询订单详情
export const tradeOrderDetail = (id, successCallback, failureCallback) => {
  getRequest(`${SRCURL}/trade/order/detail/${id}`, {}, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}
// 现货挂牌删除
export const tradeGoodsListingRemove = (id, successCallback, failureCallback) => {
  deleteRequest(`${SRCURL}/trade/goods/listing/remove/${id}`, {}, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}
// 现货挂牌分页查询
export const tradeGoodsListingPagePlat = (data, successCallback, failureCallback) => {
  postRequest(`${SRCURL}/trade/goods/listing/pagePlat`, data, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}
// 现货挂牌开户验证
export const tradeGoodsListingValidAccountResult = (successCallback, failureCallback) => {
  getRequest(`${SRCURL}/trade/goods/listing/validAccountResult`, {}, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}
// 根据企业编码查询已开户账户信息的结算银行列表
export const fundAccountInfoListSettleBankByCmpId = (data, successCallback, failureCallback) => {
  postRequest(`${baseURL}/fund/accountInfo/listSettleBankByCmpId`, data, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}
// 配送规则-查询
export const tradeDeliveryRuleQuery = (deliveryRule, successCallback, failureCallback) => {
  getRequest(`${SRCURL}/trade/delivery/rule/queryDeliveryRule?deliveryRule=${deliveryRule}`, {}, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}
// 挂牌详情
export const tradeGoodsListingDetail = (id) => {
  return new Promise((resolve, reject) => {
    getRequest(`${SRCURL}/trade/goods/listing/detail/${id}`, {}, res => (
      resolve(res)
    ), error => {
      reject(error)
    })
  })
}
// 运营后台-求购列表
export const TradeWantToBuyPage = (data, successCallback, failureCallback) => {
  postRequest(`${SRCURL}/trade/wantToBuy/page`, data, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}
// 求购详情
export const tradeWantToBuyDetail = (data, successCallback, failureCallback) => {
  getRequest(`${SRCURL}/trade/wantToBuy/detail/${data}`, {}, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}
// 提货单-平台-分页列表
export const tradeTakeGoodsBillPagePlatScmTakeGoodsBilll = (data, successCallback, failureCallback) => {
  postRequest(`${SRCURL}/trade/take/goods/bill/pagePlatScmTakeGoodsBill`, data, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}
// 过户单-平台-分页列表
export const tradeOrderTransferBillPagePlatTransferBill = (data, successCallback, failureCallback) => {
  postRequest(`${SRCURL}/trade/order/transfer/bill/pagePlatTransferBill`, data, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}
// 过户单-详情
export const tradeOrderTransferBillDetail = (data, successCallback, failureCallback) => {
  getRequest(`${SRCURL}/trade/order/transfer/bill/detail/${data}`, {}, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}
// 发票管理分页
export const invoiceIssureQueryByPage = (data, successCallback, failureCallback) => {
  postRequest(`${baseURL}/invoiceIssure/queryByPage`, data, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}
// 发票详情
export const invoiceIssureInvoiceDetail = (data, successCallback, failureCallback) => {
  postRequest(`${baseURL}/invoiceIssure/invoiceDetail`, data, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}
// 店铺分页
export const shopListPage = (data, successCallback, failureCallback) => {
  postRequest(`${SRCURL}/shop/listPage`, data, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}
// 店铺审核
export const reviewShop = (data, successCallback, failureCallback) => {
  postRequest(`${SRCURL}/shop/reviewShop`, data, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}

// 店铺关闭
export const closeShop = (data, successCallback, failureCallback) => {
  postRequest(`${SRCURL}/shop/closeShop`, data, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}
// 店铺关闭
export const queryShopDesc = (id, successCallback, failureCallback) => {
  getRequest(`${SRCURL}/shop/queryShopDesc/${id}`, {}, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}
